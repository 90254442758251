@import "../../variables";

#header {
  #mobile-header {
    display: none;
  }

  .group-12166 {
    visibility: hidden;
  }

  header {
    background: #1a1b1f;
    color: #fff;
    padding: 1% 0 1% 2%;
    display: flex;
    width: 100%;
    height: 75px;

    .hamburger_left {
      display: none;
    }

    .left {
      display: flex;
      align-items: center;
      flex-grow: 1;

      form {
        display: flex;
        width: 95%;
        height: 35px;

        label {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          background-color: #212428;
          padding: 0 10px;
          border-radius: 5px;
          color: $lightash;

          svg {
            font-size: 18px;
            margin-right: 10px;
          }

          input {
            flex-grow: 1;
            max-height: 100%;
            background-color: transparent;
            border: none;
          }

          input:focus {
            outline: none;
            border: none;
          }
        }
      }
    }

    .right {
      width: 70%;
      display: flex;
      justify-content: space-between;

      ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0 0 0 50px;
        padding: 0;
        flex-grow: 1;

        li {
          padding: 0;
          padding: 0 10px;
          border-left: 2px solid transparent;
          max-height: 80%;
          display: flex;
          align-items: center;
          max-height: 40%;
          font-size: 12px;

          a {
            color: $white;
          }

          button {
            padding: 0 5px !important;
            background: transparent;
            border: none;
            color: $white;
            font-size: 12px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              font-size: 18px;
              margin-left: 3px;
              font-weight: normal;
              color: $lightash;
            }
          }
        }

        li.active {
          border-left-color: $blue;
          font-family: Gothambold;

          a {
            color: $blue;
          }
        }
      }

      .button_container {
        display: flex;
        align-items: center;
        max-height: 100%;

        .profile_box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-height: 100%;

          .img_container {
            position: relative;
            left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
          }

          button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            background-color: transparent;
            border: none;
            color: $white;
            padding: 0 2rem 0 0.5rem !important;

            span.material-icons {
              font-size: 18px;
              margin-left: 3px;
              font-weight: normal;
              color: $lightash;
            }

            img {
              position: relative;
              top: 5px;
            }

            div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-right: 15px;
              // font-size: 13px;

              span.balance {
                color: rgb(13, 212, 13);
              }
            }
          }
        }

        a {
          margin: 0 5px;
          display: flex;
          align-items: center;

          button {
            font-size: 11px;
            border-radius: 3px;
            padding: 5px 15px 4px !important;
            font-family: Gothambook;
          }

          button.blue {
            background-color: $blue;
            border-color: $blue;
          }

          button.red {
            background-color: $red;
            border-color: $red;
          }
        }
      }
    }
  }
}

@media (max-width: 1095px) {
  #header {
    #main-header {
      display: none;
    }

    #mobile-header {
      display: flex;
      padding: 1% 2%;

      .left {
        width: 60%;

        .logo {
          max-width: 100%;

          img {
            max-width: 100%;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        [ant-click-animating-without-extra-node]:after {
          -webkit-animation: none !important;
          -moz-animation: none !important;
          -o-animation: none !important;
          -ms-animation: none !important;
          animation: none !important;
        }

        .options-menu {
          width: fit-content;
          display: flex;
          align-items: center;
          background-color: transparent;
          color: white;
          border: none;
          padding: 4px 15px 4px 7.5px !important;
        }

        .options-menu:focus {
          outline: none;
        }

        .mobile-menu {
          padding: 1rem;
        }

        .button_container {
          .profile_box {
            button:nth-child(1) {
              padding: 0 7.5px !important;

              span {
                font-size: 18px;
                position: relative;
                top: 1px;
              }
            }

            button:nth-child(2) {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 7.5px 4px 7.5px !important;
              position: relative;
              top: -4px;
            }
          }
        }
      }
    }
  }
}

@media(max-width: 575px) {
  .navbar {
    margin-left: -25px;
    left: 40px;
    height: 93px;
    width: 360px;
  }

  .home-btn, 
  .about-btn, 
  .services-btn, 
  .careers-btn, 
  .blog-btn,
  .linkedin-1,
  .group-12592-item {
    display: none;
  }

  .btn-container {
    height: 74px;
    min-width: 358px;
    padding: 2.5px 75px;
  }

  .tse-logo-orange4x-1 {
    top: 32px;
    margin-left: -33px;
  }

  .group-12592 {
    left: 16px;
    height: 74px;
    top: 18px;
    width: 75px;
  }

  .group-12166 {
    align-items: center;
    display: flex;
    visibility: visible !important;
    margin-bottom: 2px;
    margin-left: 152px;
    min-width: 120px;
  }

  .text-9 {
    font-size: 16px;
  }

  .ant-dropdown-menu-item, 
  .ant-dropdown-menu-submenu-title {
    padding: 12px 24px;
  }

  .ant-dropdown-menu {
    width: 200px;
    border-radius: 0px;
    margin-left: -136px;
    margin-top: 24px;
  }

  // .m-menu {
  //   --z-index: 6;
  //   background-color: transparent;
  //   height: 48px;
  //   left: 391px;
  //   opacity: 1;
  //   position: absolute;
  //   top: 21px;
  //   width: 49px;
  //   display: block;
  // }
}


@media (max-width: 1125px) {
  #header {
         
    header {
     
      position: relative;

      .hamburger_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 70%;
        align-self: center;

        width: 35px;
        left: 10px;
        max-height: 100%;
        margin-right: 15px;
        border: none;
        background-color: transparent;

        span {
          font-size: 30px;
        }
      }

      .hamburger_left:focus {
        outline: none;
      }
     
      .left {
        display: flex;
        flex-grow: 1;
        align-items: center;

        form {
          display: flex;
          width: 80%;
  
          label {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            background-color: #212428;
            padding: 0 10px;
            border-radius: 5px;
            color: $lightash;

            svg {
              font-size: 18px;
              margin-right: 10px;
            }
  
            input {
              flex-grow: 1;
              max-height: 100%;
              background-color: transparent;
              border: none;
            }
            
            input:focus {
              outline: none;
              border: none;
            }
          }
        }
      }

      .right {
        width: 65%;
        display: flex;
        justify-content: space-between;

        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          flex-grow: 1;

          li {
            padding: 0;
            padding: 0 10px;
            border-left: 2px solid transparent;
            max-height: 80%;
            display: flex;
            align-items: center;
            
            a {
              color: $white;
            }

            button {
              padding: 0;
              background: transparent;
              border: none;
              color: $white;
            }
          }

          li.active {
            border-left-color: $blue;

            a {
              color: $blue;
            }
          }
        }

        .button_container {
          display: flex;
          align-items: center;
          max-height: 100%;

          a {
            margin: 0 5px;
            display: flex;
            align-items: center;

            button {
              font-size: 11px;
              border-radius: 3px;
            }

            button.blue {
              background-color: $blue;
              border-color: $blue;
            }

            button.red {
              background-color: $red;
              border-color: $red;
            }
          }
        }
      } 
    }
  }
}