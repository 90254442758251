@media (max-width: 1050px) {}

@media (max-width: 600px) {}

@media (max-width: 575px) {
  .landing-page {
    height: 5150px;
    mix-blend-mode: normal;
    overflow: hidden;
    position: relative;
    width: 390px;
  }

  .group-12200 {
    left: 284px;
  }

  .group-12243 {
    margin-right: 1070px;
  }

  .rectangle-620 {
    height: 329px;
    margin-left: 109px;
    margin-top: 7.18px;
    width: 317px;
  }

  .title {
    font-size: 73px;
    left: 165px;
    line-height: 73px;
    top: 57px;
    width: 427px;
  }

  .its-been-an-amazing {
    line-height: 32px;
    margin-top: -60px;
    width: 350px;
  }

  .flex-row {
    margin-top: 175px;
  }

  .if-youre-looking-fo {
    left: 210px;
    width: 343px;
    top: 350px;
  }

  .flex-row-1 {
    width: 390px;
    height: 363px;
    margin-left: -525px;
  }

  .overlap-group23 {
    margin-left: 40px;
    padding: 119.5px 19.5px;
    width: 317px;
  }

  .lorem-ipsum-dolor-si {
    left: 95px;
    width: 317px;
  }

  .delivering-services-that-stand-out-1 {
    left: 379px;
    line-height: 24px;
  }

  .overlap-group20 {
    height: 892px;
    left: 367px;
    position: absolute;
    top: 0;
    // width: 1496px;
  }

  .confetti8 {
    left: -365px;
  }

  .text-filled-icon-6 {
    left: 576px;
    top: 1190px;
  }

  .primary-default-small-button-icon-2 {
    left: 465px;
    top: 1285px;
  }

  .flex-row-2 {
    margin-top: -110px;
    margin-left: 16px;
  }

  .flex-col-1 {
    margin-left: -362.5px;
    margin-top: 575px;
  }

  .overlap-group25 {
    margin-top: -100px;
  }

  .why-they-call-us-the-pr-maestro {
    width: 259px;
    line-height: 24px;
  }

  .confetti4 {
    margin-left: 55px;
  }

  .flex-row-3 {
    margin-left: 16px;
    margin-top: 18px;
  }

  .group-12491 {
    margin-left: -45px;
    margin-top: -60px;
  }

  .group-12491 {
    margin-left: -45px;
    margin-top: -60px;
  }

  .group-12493 {
    margin-left: -338px;
    margin-top: 240px;
  }

  .group-459 {
    margin-left: -337px;
    margin-top: 590px;
  }

  .group-488 {
    left: 14px;
    top: 270px;
  }

  .group-487 {
    margin-left: -338px;
    margin-top: 300px;
  }

  .group-486 {
    margin-left: -337px;
    margin-top: 610px;
  }

  .anniversary_section {
    margin-left: -525.51px;
    margin-top: 60px;
  }

  .overlap-group17 {
    padding: 40px 0;
    width: 359px;
  }

  .flex-row-4 {
    margin-top: 500px;
    align-self: flex-start;
    margin-left: 378px;
  }

  .group-503 {
    margin-top: 31px;
    margin-left: 0px;
  }

  .group-472 {
    top: 225px;
  }

  .group-12244 {
    margin-left: 0px;
    margin-top: 75px;
  }

  .overlap-group-4,
  .ellipse {
    margin-left: 130px;
  }

  // .overlap-group21 {
  //   margin-left: 267px;
  // }

  .group-12497 {
    top: 56px;
    left: 185px;
  }

  .overlap-group7 {
    margin-left: 0px !important;
  }

  .companies-that-trust-teksight-edge {
    width: 339px;
    line-height: 24px;
  }

  .group-430 {
    margin-right: 62px;
    margin-top: 37px;
  }

  .overlap-group-1 {
    height: 100px;
    margin-left: 8px;
    min-width: 100px;
    padding: 57.5px 35.5px;
  }

  .logo {
    margin-top: -16px;
  }

  .that-inspire-emotions {
    font-size: var(--font-size-xxxl);
    width: 315px;
    left: 230px;
    top: 154px;
    line-height: 44px;
  }

  .create {
    font-size: var(--font-size-xxxl);
  }

  .experiences {
    letter-spacing: 0;
    margin-left: -271px;
    margin-top: 70px;
  }

  .we {
    margin-left: 256px;
  }

  .frame-103 {
    align-self: flex-start;
    height: 67px;
    margin-left: 13px;
    min-width: 108px;
    padding: 0 20px;
    margin-top: 3px;
  }
}

@media (max-width: 365px) {}

@media (max-width: 340px) {}