//colors
$darkash: #525862;
// $lightash: #979797;
$lightash: #9fafb5;
$tealish: #5d6c76;
$lightteal: #9fafb5;
$dark: #212428;
$blue: #335191;
$white: #ffffff;
$red: #ff0039;
$yellow: #FFB201;
$green: #0dd40d;

:root {
    --black: 
  rgba(0, 0, 0, 1);
    --background-colour040505: 
  rgba(4, 5, 5, 1);
    --eerie-black: 
  rgba(30, 30, 30, 1);
    --brand-colour252525: 
  rgba(37, 37, 37, 1);
    --text-colour333333: 
  rgba(51, 51, 51, 1);
    --royal-blue: 
  rgba(52, 92, 235, 1);
    --success-message3cc13b: 
  rgba(60, 193, 59, 1);
    --text-colour4f4f4f: 
  rgba(79, 79, 79, 1);
    --daisy-bush: 
  rgba(88, 27, 152, 1);
    --storm-dust: 
  rgba(97, 97, 97, 1);
    --gray: 
  rgba(126, 126, 126, 1);
    --stack: 
  rgba(138, 138, 138, 1);
    --stroke-coloura7a7a7: 
  rgba(166, 166, 166, 1);
    --hibiscus: 
  rgba(179, 30, 111, 1);
    --mist-gray: 
  rgba(196, 196, 196, 1);
    --cloud: 
  rgba(200, 199, 199, 1);
    --iron: 
  rgba(215, 215, 215, 1);
    --alto: 
  rgba(218, 218, 218, 1);
    --mercury: 
  rgba(230, 230, 230, 1);
    --brand-coloureea120: 
  rgba(231, 145, 1, 1);
    --carrot-orange-2: 
  rgba(238, 160, 31, 0.102);
    --carrot-orange: 
  rgba(238, 160, 31, 0.18);
    --carrot-orange-3: 
  rgba(238, 160, 31, 0.239);
    --carrot-orange-4: 
  rgba(238, 160, 31, 0.251);
    --fuel-yellow: 
  rgba(238, 161, 32, 0.698);
    --error-messagef03738: 
  rgba(240, 55, 56, 1);
    --gallery: 
  rgba(240, 240, 240, 1);
    --strawberry: 
  rgba(252, 81, 133, 1);
    --background-colourfff9ef: 
  rgba(255, 249, 239, 1);
    --white: 
  rgba(255, 255, 255, 0.502);
    --text-colourffffff: 
  rgba(255, 255, 255, 1);
  
    --font-size-s: 14px;
    --font-size-m: 16px;
    --font-size-l: 18px;
    --font-size-xl: 20px;
    --font-size-xxl: 24px;
    --font-size-xxxl: 48px;
    --font-size-xxxxl: 68px;
  
    --font-family-koho: "KoHo";
    --font-family-rozha_one: "Rozha One";
  }
  
  .rozhaone-normal-mine-shaft-24px {
    color: var(--text-colour333333);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    font-style: normal;
  }
  .kohobt4regular {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-s);
    letter-spacing: 0px;
    font-weight: 400;
    font-style: normal;
  }
  .kohobt3regular {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-m);
    letter-spacing: 0px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-tangerine-24px {
    color: var(--brand-coloureea120);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-text-colourffffff {
    border-width: 1px;
    border-style: solid;
    border-color: var(--text-colourffffff);
  }
  .rozhaone-normal-island-spice-16px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-island-spice-18px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-tangerine-18px {
    color: var(--brand-coloureea120);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
  .kohobt2regular {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-l);
    letter-spacing: 0px;
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-carrot-orange-4 {
    border-width: 1px;
    border-style: solid;
    border-color: var(--carrot-orange-4);
  }
  .rozhaone-normal-abbey-20px {
    color: var(--text-colour4f4f4f);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xl);
    font-weight: 400;
    font-style: normal;
  }
  .koho-semi-bold-carrot-orange-200px {
    color: var(--carrot-orange-2);
    font-family: var(--font-family-koho);
    font-size: 200px;
    font-weight: 600;
    font-style: normal;
  }
  .border-1px-background-colour040505 {
    border-width: 1px;
    border-style: solid;
    border-color: var(--background-colour040505);
  }
  .rozhaone-normal-gallery-16px {
    color: var(--gallery);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-midnight-moss-68px {
    color: var(--background-colour040505);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-black-32px {
    color: var(--black);
    font-family: var(--font-family-rozha_one);
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-tangerine-48px {
    color: var(--brand-coloureea120);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-mist-gray-16px {
    color: var(--mist-gray);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-white-48px-2 {
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-shark-20px {
    color: var(--brand-colour252525);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xl);
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-brand-coloureea120 {
    border-width: 1px;
    border-style: solid;
    border-color: var(--brand-coloureea120);
  }
  .rozhaone-normal-island-spice-22px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-island-spice-68px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .koho-normal-stack-32px {
    color: var(--stack);
    font-family: var(--font-family-koho);
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
  }
  .kohobt3semibold {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-m);
    letter-spacing: 0px;
    font-weight: 600;
    font-style: normal;
  }
  .koho-normal-tangerine-12px {
    color: var(--brand-coloureea120);
    font-family: var(--font-family-koho);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-mist-gray {
    border-width: 1px;
    border-style: solid;
    border-color: var(--mist-gray);
  }
  .border-1px-stroke-coloura7a7a7 {
    border-width: 1px;
    border-style: solid;
    border-color: var(--stroke-coloura7a7a7);
  }
  .kohobt1semibold {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-xl);
    letter-spacing: 0px;
    font-weight: 600;
    font-style: normal;
  }
  .border-2px-brand-coloureea120 {
    border-width: 2px;
    border-style: solid;
    border-color: var(--brand-coloureea120);
  }
  .rozhaone-normal-gallery-40px {
    color: var(--gallery);
    font-family: var(--font-family-rozha_one);
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
  }
  .koho-bold-gallery-18px {
    color: var(--gallery);
    font-family: var(--font-family-koho);
    font-size: var(--font-size-l);
    font-weight: 700;
    font-style: normal;
  }
  .border-1-5px-brand-coloureea120 {
    border-width: 1.5px;
    border-style: solid;
    border-color: var(--brand-coloureea120);
  }
  .rozhaone-normal-mercury-16px {
    color: var(--mercury);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-mine-shaft-20px {
    color: var(--text-colour333333);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-gallery-32px {
    color: var(--gallery);
    font-family: var(--font-family-rozha_one);
    font-size: 32px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-tangerine-68px {
    color: var(--brand-coloureea120);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-white-68px {
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .kohobt2semibold {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-l);
    letter-spacing: 0px;
    font-weight: 600;
    font-style: normal;
  }
  .rozhaone-normal-gray-68px {
    color: var(--gray);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-island-spice-20px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xl);
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-cloud {
    border-width: 1px;
    border-style: solid;
    border-color: var(--cloud);
  }
  .rozhaone-normal-white-24px {
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    font-style: normal;
  }
  .kohobt1regular {
    font-family: var(--font-family-koho);
    font-size: var(--font-size-xl);
    letter-spacing: 0px;
    font-weight: 400;
    font-style: normal;
  }
  .koho-semi-bold-carrot-orange-220px {
    color: var(--carrot-orange);
    font-family: var(--font-family-koho);
    font-size: 220px;
    font-weight: 600;
    font-style: normal;
  }
  .rozhaone-normal-white-18px {
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }
  .border-1px-storm-dust {
    border-width: 1px;
    border-style: solid;
    border-color: var(--storm-dust);
  }

  .rozhaone-normal-island-spice-48px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxl);
    font-weight: 400;
    font-style: normal;
  }

  @media (max-width: 575px) {
    .rozhaone-normal-island-spice-48px {
      font-size: var(--font-size-xxl);
    }

    .rozhaone-normal-white-48px {
      font-size: var(--font-size-xl) !important;
    }

    .rozhaone-normal-island-spice-68px {
      font-size: var(--font-size-xxxl);
    }

    .rozhaone-normal-tangerine-48px {
      font-size: var(--font-size-xxl);
    }

    .rozhaone-normal-white-40px {
      font-size: 20px !important;
    }

    .rozhaone-normal-gallery-32px {
      font-size: 18px;
    }
  }

  .rozhaone-normal-gray-100px {
    color: var(--gray);
    font-family: var(--font-family-rozha_one);
    font-size: 100px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-white-40px {
    color: var(--white);
    font-family: var(--font-family-rozha_one);
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-white-68px-2 {
    color: var(--text-colourffffff);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxxl);
    font-weight: 400;
    font-style: normal;
  }
  .koho-normal-mist-gray-12px {
    color: var(--mist-gray);
    font-family: var(--font-family-koho);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
  }
  .koho-normal-mist-gray-16px {
    color: var(--mist-gray);
    font-family: var(--font-family-koho);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-gallery-24px {
    color: var(--gallery);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-island-spice-24px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxl);
    font-weight: 400;
    font-style: normal;
  }

  .rozhaone-normal-white-48px {
    color: var(--white);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-xxxl);
    font-weight: 400;
    font-style: normal;
  }

  .rozhaone-normal-island-spice-40px {
    color: var(--background-colourfff9ef);
    font-family: var(--font-family-rozha_one);
    font-size: 40px;
    font-weight: 400;
    font-style: normal;
  }
  .rozhaone-normal-abbey-18px {
    color: var(--text-colour4f4f4f);
    font-family: var(--font-family-rozha_one);
    font-size: var(--font-size-l);
    font-weight: 400;
    font-style: normal;
  }